.__head_form {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 2rem;
}

.__head_form .__btn_back {
    display: grid;
    width: 30px;
    height: 30px;
    place-items: center;
    border-radius: 100px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__loader_box {
    width: 100%;
    height: 300px;
    display: grid;
    place-items: center;
    border-radius: 20px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__loader_box .__loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--secondary);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.__form {
    width: 100%;
    padding: 1rem;
    border-radius: 20px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__form .__form_group {
    width: 100%;
    margin-bottom: 1rem;
}

.__form .__form_group .__form_flex {
    display: flex;
    gap: 1rem;
    overflow: hidden;
    overflow-x: auto;
}

.__form .__form_group .__label {
    display: block;
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.__form .__form_group .__label_images {
    display: grid;
    width: 120px;
    height: 120px;
    cursor: pointer;
    place-items: center;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
}

.__form .__form_group .__label_images_proyects {
    display: grid;
    width: 120px;
    height: 200px;
    min-width: 120px;
    cursor: pointer;
    place-items: center;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
}

.__form .__form_group .__form_flex .__previews {
    position: relative;
    width: 120px;
    height: 120px;
    min-width: 120px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    border: 1px solid #E4E4E4;
    background-repeat: no-repeat;
}

.__form .__form_group .__form_flex .__previews_proyects {
    position: relative;
    width: 120px;
    height: 200px;
    min-width: 120px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    border: 1px solid #E4E4E4;
    background-repeat: no-repeat;
}

.__form .__form_group .__form_flex .__previews .__btn_delete,
.__form .__form_group .__form_flex .__previews_proyects .__btn_delete {
    position: absolute;
    display: grid;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    place-items: center;
    border-radius: 100px;
    background-color: #FF0000;
}

.__form .__form_group .__form_flex .__previews .__btn_delete .tabler-icon,
.__form .__form_group .__form_flex .__previews_proyects .__btn_delete .tabler-icon {
    stroke: #FFFFFF;
}

.__form .__form_group input[type="file"] {
    display: none;
}

.__form .__form_group .__form_control {
    width: 100%;
}

.__form .__form_group .__form_control .__entry,
.__form .__form_group .__form_control .__select {
    width: 100%;
    height: 60px;
    padding: 0rem 1rem;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__form .__form_group .__form_control .__entry:focus {
    border: 2px solid var(--secondary);
}

.__form .__form_group .__form_control .__textbox {
    width: 100%;
    resize: none;
    height: 100px;
    padding: 1rem;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__form .__form_group .__form_control .__textbox:focus {
    border: 2px solid var(--secondary);
}

.__form .__form_group .__btn_send {
    width: 100%;
    height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: 10px;
    background-color: var(--secondary);
}