.__article {
    width: 100%;
    border-bottom: 1px solid #E4E4E4;
}

.__article .__article_head {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
    justify-content: space-between;
}

.__article .__article_head .__col1 p {
    font-size: .8rem;
    color: #888888;
}

.__article .__article_head .__col2 {
    position: relative;
    width: 40px;
    height: 40px;
}

.__article .__article_head .__col2 .__btn_submenu {
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
    background-color: #f5f5f5;
}

.__article .__article_head .__col2 .__subnav {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 40px;
    gap: .5rem;
    display: none;
    width: 180px;
    padding: .5rem;
    border-radius: 10px;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-shadow: 0px 4px 4px 0px rgba(44, 44, 44, .2);
}

.__article .__article_head .__col2 .__subnav--active {
    display: flex;
}

.__article .__article_head .__col2 .__subnav .__item_subnav {
    width: 100%;
    display: flex;
    gap: .4rem;
    padding: .2rem;
    cursor: pointer;
    border-radius: 5px;
    align-items: center;
}

.__article .__article_head .__col2 .__subnav .__item_subnav:hover {
    background-color: #f9f9f9;
}

.__article .__article_head .__col2 .__subnav .__item_subnav_danger {
    color: #FF0000;
    background-color: rgb(255, 0, 0, .2);
}

.__article .__article_head .__col2 .__subnav .__item_subnav_danger:hover {
    background-color: rgb(255, 0, 0, .2);
}

.__article .__article_head .__col2 .__subnav .__item_subnav .__ico_subnav {
    display: grid;
    width: 30px;
    height: 30px;
    place-items: center;
}

.__article .__article_body {
    padding: .8rem;
}

.__article .__article_body .__images {
    width: 100%;
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    padding: 1rem 0rem;
}

.__article .__article_body .__images .__img {
    width: 120px;
    height: 120px;
    min-width: 120px;
    background-size: cover;
    border: 1px solid #E4E4E4;
    background-position: center;
    background-repeat: no-repeat;
}