* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-family);
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #F2F0F4;
    font-family: var(--font-family);
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
}

input, select, textarea {
    border: none;
    outline: none;
    font-family: var(--font-family);
}

ul, li, ol {
    list-style: none;
}

label {
    user-select: none;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

img {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar-thumb {
    background: rgb(4, 27, 46, 1);
    border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
    background: rgb(4, 27, 46, .8);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:active {
    background-color: rgb(4, 27, 46, .8);
}

body::-webkit-scrollbar-track {
    background: rgb(4, 27, 46, .4);
    border-radius: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
.body::-webkit-scrollbar-track:hover,
.body::-webkit-scrollbar-track:active {
  background: rgb(4, 27, 46, .4);
}

#root .__alert {
    position: absolute;
    top: 50px;
    right: 50px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding: .8rem 1rem;
}

#root .__alert_warning {
    color: #FFFF00;
    background-color: rgb(165, 165, 0);
}


#root .__alert_success {
    color: #00ff00;
    background-color: rgb(0, 165, 88);
}


#root .__alert_error {
    color: #ff0000;
    background-color: rgb(151, 0, 0);
}

#root .__alert .__btn_close {
    display: grid;
    width: 30px;
    height: 30px;
    place-items: center;
}