.__list .__mail_box {
    display: flex;
    width: 100%;
    padding: .8rem .5rem;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    background-color: #FFFFFF;
    justify-content: space-between;
}

.__list .__mail_box .__col1 {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.__list .__mail_box .__col1 .__image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100px;
    background-color: #F2F0F4;
}

.__list .__mail_box .__col1 div p {
    color: #888888;
    font-size: .9rem;
}

.__list .__mail_box .__col2 .__btn_submenu {
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
    background-color: #f5f5f5;
}