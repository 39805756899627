.__net {
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__net .__line {
    width: 100%;
    height: 1px;
    margin: .8rem 0rem;
    background-color: #E4E4E4;
}

.__net .__network {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #E4E4E4;
}

.__net .__network .__ico {
    position: absolute;
    display: grid;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    place-items: center;
}

.__net .__network .__entry {
    width: 100%;
    height: 50px;
    padding: 1rem 60px;
}

.__net .__network .__btn {
    position: absolute;
    display: grid;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    place-items: center;
}