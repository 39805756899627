.__header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #EEEEEE;
}

.__box_header {
    width: 100%;
    height: inherit;
}

.__box_header .__nav {
    width: 100%;
    height: inherit;
}

.__box_header .__nav .__nav_list {
    width: 100%;
    display: flex;
    gap: 1rem;
    height: inherit;
    padding: .2rem 0rem;
    align-items: center;
    justify-content: space-between;
}

.__box_header .__nav .__nav_list .__item_nav {
    width: 100%;
    height: 100%;
}

.__box_header .__nav .__nav_list .__item_nav .__a_nav {
    display: grid;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    place-items: center;
}

.__box_header .__nav .__nav_list .__item_nav .__a_nav:hover {
    background-color: #e5e5e5;
}

.__box_header .__nav .__nav_list .__item_nav .__a_nav .tabler-icon {
    width: 28px;
    height: 28px;
    stroke-width: 1.5;
    stroke: #636363;
}

.__box_header .__nav .__nav_list .__item_nav .__a_nav--active {
    border-radius: 0;
    border-bottom: 3px solid var(--secondary);
}

.__box_header .__nav .__nav_list .__item_nav .__a_nav--active:hover {
    background: none;
}

.__box_header .__nav .__nav_list .__item_nav .__a_nav--active .tabler-icon {
    stroke-width: 0;
    fill: var(--secondary);
}