.__content_app {
    position: relative;
    width: 90%;
    margin: auto;
}

.__content_app .__main {
    width: 100%;
    padding: 2rem 0rem;
}

.__content_app .__main .__title {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.__content_app .__main .__title .__a_btn {
    display: grid;
    font-weight: 500;
    padding: .8rem 1rem;
    place-items: center;
    border-radius: 10px;
    color: var(--secondary);
    background-color: var(--bg-secondary);
}

.__content_app .__main .__list {
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
}

.__content_app .__main .__list .__skeleton {
    position: relative;
    width: 100%;
    height: 70px;
    overflow: hidden;
    border-radius: 10px;
    background-color: #e1e1e1;
}

.__content_app .__main .__list .__skeleton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #e1e1e1 25%, #f1f1f1 50%, #e1e1e1 75%);
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@media (min-width: 1024px) {

    .__content_app {
        width: 60%;
    }

}

@media (min-width: 1440px) {

    .__content_app {
        width: 40%;
    }

}